import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Paper from "@mui/material/Paper";
import BlueConfigImageButtonTablette from '../../images/hexagone-blue-config-tab.svg';

export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));
export const CristalButtonPrimaire = styled(Button)(({theme}) => ({
    color: "#FFFFFF",
    backgroundColor: "#00508f",
    border: '2px solid #00508f',
    padding: '12px 20px',
    fontSize: '14px',
    textTransform: 'none',
    borderRadius: '50px',
    lineHeight: '1.5',
    '&:hover': {
        backgroundColor: '#004072',
        border: '2px solid #004072',
    },
}));
export const CristalButtonSecondaire = styled(Button)(({theme}) => ({
    color: theme.palette.mode === 'light' ? "#00508F" : "#FFFFFF",
    border: theme.palette.mode === 'light' ? '2px solid #00508F' : '2px solid #FFFFFF',
    padding: '12px 20px',
    fontSize: '14px',
    lineHeight: '1.5',
    textTransform: 'none',
    borderRadius: '50px',
    '&:hover': {
        backgroundColor: '#E6EEF4',
        color: '#00508F',
        border: '2px solid #00508F',
    },
}));
export const CristalBlueButton = styled(ButtonBase)(() => ({
    position: 'relative',
    height: '50px',
    width: '50px',
    backgroundImage: `url(${BlueConfigImageButtonTablette})`,
    backgroundSize: '50px 50px',
}));

export const CristalFilterButton = styled(Button)(({theme}) => ({
    textTransform: 'none',
    ...theme.typography.body2,
    backgroundColor: theme.palette.mode === 'light' ? 'var(--background-global-03-light)' : 'var(--background-global-03-dark)',
    border: theme.palette.mode === 'light' ? '2px solid var(--border-card-light)' : '2px solid var(--border-card-dark)',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'light' ? '#E5F9FE' : '#003B5D',
    },
    borderRadius: '10px',
    width: '100%',
    height: '65px',
    padding: '0 20px',
    marginBottom: '20px'
}));
