import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";

/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Home';
export const titleAlveole = 'Home';

/************************ DeV ******************************************************/
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const zUrlBack = "https://api-home-dev.cristal-hub.fr";
export const domain = '.cristal-hub.fr';

/************************ LOCAL DeV ************************************************/
// export const zUrlBackNotifications = "https://localhost:8001";
// export const zUrlBack = "https://localhost:8000";
// export const domain = 'localhost';

export const CristalContainerAlveoleMulti = styled(Grid)(() => ({
    '@media (min-width: 0px) and (max-width: 566px)': {
       width: '380px'
    },
    '@media (min-width: 567px) and (max-width: 656px)': {
        width: '518px'
    },
    '@media (min-width: 657px)': {
        width: '600px'
    },
}));
export const alveolesParDefaut = [
    {
        id: 1,
        icon: 'icon-alv-meteo-sani-blanc',
        title: 'Météo sanitaire',
        label: 'actualite',
        link: 'https://meteosanitaire-dev.cristal-hub.fr/'
    },
    {
        id: 2,
        icon: 'icon-alv-contact-blanc',
        title: 'Mes contacts',
        label: 'contacts',
        link: 'https://mescontacts-dev.cristal-hub.fr/'
    },
    {
        id: 3,
        icon: 'icon-alv-form-blanc',
        title: 'Mes formations',
        label: 'espace_formation',
        link: 'https://mesformations-dev.cristal-hub.fr/'
    },
    {
        id: 4,
        icon: 'icon-alv-rdv-blanc',
        title: 'Mes rendez-vous',
        label: 'rendez_vous',
        link: 'https://mesrendezvous-dev.cristal-hub.fr'
    },
    {
        id: 5,
        icon: 'icon-alv-docs-veto-blanc',
        title: 'Mes documents véto',
        label: 'documents',
        link: 'https://mesdocumentsveto-dev.cristal-hub.fr/'
    },
    {
        id: 6,
        icon: 'icon-alv-docs-tech-blanc',
        title: 'Mes documents techniques',
        label: 'documents_techniques',
        link: ''
    },
    {
        id: 7,
        icon: 'icon-alv-factures-blanc',
        title: 'Mes factures',
        label: 'factures',
        link: 'https://mesfactures-dev.cristal-hub.fr/'
    },
    {
        id: 8,
        icon: 'icon-alv-espace-labo-blanc',
        title: 'Mon espace labo',
        label: 'espace_labo',
        link: 'https://monespacelabo-dev.cristal-hub.fr/'
    },
    {
        id: 9,
        icon: 'icon-alv-dmd-pdts-blanc',
        title: 'Demande de produits',
        label: 'commande',
        link: 'https://demandeproduits-dev.cristal-hub.fr/'
    },
    {
        id: 10,
        icon: 'icon-alv-conso-blanc',
        title: 'Mes consos',
        label: 'consommations',
        link: 'https://mesconsos-dev.cristal-hub.fr'
    },
];

/**
 * Fin constantes spécifiques alvéole
 */
/************************ Constantes ***********************************************/
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
