import React, {useEffect, useState} from 'react';
import Cookies from "js-cookie";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import toast from 'react-hot-toast';
import {useTheme} from "@mui/material/styles";

import CristalLogoLight from './../images/logo-cristalhub-light.svg';
import CristalLogoDark from './../images/logo-cristalhub-dark.svg';
import * as constantes from "./Parts/AlveoleConstants";
import {CristalLogo, StyledBadge, useStyles} from "./Parts/ComponentLayout";
import {CristalBlueButton} from "./Parts/ComponentHome";
import NotificationMenu from "./Parts/NotificationMenu";
import {onMessageListener, requestForToken} from "../firebase/firebaseConfig";
import NotificationFirebase from "../firebase/NotificationFirebase";

const Layout = ({children, calendars, documents, updateCalendars, updateDocuments}) => {
    const [isTokenFound, setTokenFound] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const theme = useTheme();
    useEffect(() => {
        let cookieTheme = Cookies.get('theme');
        requestPermissionForNotification();
        onMessageListener()
            .then((payload) => {
                setNotification({title: payload.notification.title, body: payload.notification.body});
                toast.custom( // foreground message
                    (t) => (
                        <div
                            style={{
                                fontSize: '14px',
                                background: cookieTheme === "light" ? 'var(--background-global-03-light)' : 'var(--background-global-03-dark)',
                                padding: '10px',
                                borderRadius: '4px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <h3>{payload.notification.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: payload.notification.body.replace(/\n/g, '<br>')}}/>
                        </div>
                    ),
                    {duration: 15000,}
                );
                console.log(payload);
            })
            .catch(err => console.log('failed: ', err));
    }, []);


    const requestPermissionForNotification = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                await requestForToken(setTokenFound);
            } else {
                console.log("Unable to get permission to notify.");
            }
        } catch (error) {
            console.error('Error during permission request', error);
        }
    };

    const classes = useStyles();
    const [bShowNotifications, setBShowNotifications] = React.useState(false);
    const [bConsents, setBConsents] = React.useState(false);
    const [bLoading, setBLoading] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const moment = require('moment');
    require('moment/locale/fr');

    useEffect(() => {
        const timer = setTimeout(() => {
            setBLoading(false);
        }, 2000); //  milliseconds
        return () => clearTimeout(timer);
    }, []);

    const fOpenNotifications = () => {
        setBShowNotifications(true);
    }
    const fCloseNotifications = () => {
        setBShowNotifications(false);
    }
    const fRemoveNotifications = (value) => {
        axios.post(`${constantes.zUrlBackNotifications}/remove-notification`, {
                idUser: Cookies.get('idUser'),
                idNotification: value
            },
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            })
            .then((response) => {
                console.log(response.data['notification']['externalId']) // élément supprimé
                if (response.data['notification']['type'] === 'calendar') {
                    updateCalendars(calendars.filter((calendar) => calendar['externalId'] !== value));
                } else {
                    updateDocuments(documents.filter((doc) => doc['externalId'] !== value));
                }
            })
            .catch((error) => {
                console.log(error)
            })
        ;
    }

    // Recuperation des notifications
    const fetchNotifications = () => {
        axios.post(`${constantes.zUrlBackNotifications}/notifications`,
            {
                token: Cookies.get('token'),
                societe: Cookies.get('socId'),
                compte: Cookies.get('accountId'),
                idUser: Cookies.get('idUser')
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    // Récupération et conversion des dates du calendrier
                    const calendarList = response.data['calendarList'].map(event => {
                        event.dateEvenement = moment.utc(event.dateEvenement, 'DD-MM-YYYY hh:mm').local().format('D MMMM YYYY HH:mm');
                        return event;
                    });
                    updateCalendars(calendarList);
                    // Récupération Datas documents
                    updateDocuments(response.data['zeenDocsList']);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    useEffect(() => {
        let accountAcces;
        axios.post(`${constantes.zUrlBackNotifications}/customer-check`,
            {
                token: Cookies.get('token'),
                accountName: Cookies.get('compteParamNom')
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.status === 200) {
                    accountAcces = response.data['accountAcces'];
                }

            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                if (accountAcces !== null) {
                    console.log(accountAcces + "fetch datas...")
                    fetchNotifications();
                }
            });

    }, []);

    useEffect(() => {
        axios.post(`${constantes.zUrlBackNotifications}/notifications-consents`,
            {token: Cookies.get('token')}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                let notificationValue = response.data['consentNotif'];
                setBConsents(notificationValue)
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleCloseLoading = () => {
        setBLoading(false);
    };

    const handleProfile = () => {
        window.location.assign(constantes.zURLprofile)
    }
    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={bLoading}
                    onClick={handleCloseLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Box sx={{flexGrow: 1}}>
                    <Grid container>
                        <NotificationFirebase/>
                        <Grid item md={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    marginTop: '36px',
                                    marginBottom: '14px',
                                    minWidth: '360px'
                                }}>
                                <Grid item md={6} xs={6}>
                                    <CristalLogo
                                        src={theme.palette.mode === 'light' ? `${CristalLogoLight}` : `${CristalLogoDark}`}
                                        loading="lazy"
                                        sx={{maxWidth: '100%'}}/>
                                </Grid>
                                {Cookies.get('socId') && Cookies.get('accountId') && (
                                    <Grid item md={6} xs={6}>
                                        <Grid container
                                              direction="row"
                                              justifyContent="flex-end"
                                              alignItems="flex-start">
                                            {calendars.length === 0 && documents.length === 0 ? (
                                                <Grid item>
                                                    <CristalBlueButton
                                                        disableRipple
                                                        sx={{
                                                            cursor: 'default',
                                                            marginRight: '.5rem!important'
                                                        }}
                                                    >
                                                        <img src={require('./../images/icon-notif.svg').default}
                                                             alt="CristalHub"
                                                             className="CristalNotifButton"/>
                                                    </CristalBlueButton>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Box>
                                                        <StyledBadge theme
                                                                     badgeContent=" "
                                                                     overlap="circular">
                                                            <CristalBlueButton
                                                                disableRipple
                                                                onClick={fOpenNotifications}
                                                                sx={{marginRight: '.5rem!important'}}
                                                            >
                                                                <img
                                                                    src={require('./../images/icon-notif.svg').default}
                                                                    alt="CristalHub"
                                                                    className="CristalNotifButton"/>
                                                            </CristalBlueButton>
                                                        </StyledBadge>
                                                    </Box>
                                                    <NotificationMenu
                                                        anchorEl={anchorEl}
                                                        bShowNotifications={bShowNotifications}
                                                        fCloseNotifications={fCloseNotifications}
                                                        fRemoveNotifications={fRemoveNotifications}
                                                        calendars={calendars}
                                                        documents={documents}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                {bConsents === true ? (
                                                    <Box>
                                                        <StyledBadge
                                                            classes={{badge: classes.customBadge}}
                                                            color="default" badgeContent=" " overlap="circular">
                                                            <CristalBlueButton onClick={handleProfile}
                                                                               disableRipple>
                                                                <img
                                                                    src={require('./../images/icon-settings.svg').default}
                                                                    alt="CristalHub"
                                                                    className="CristalSettingsButton"/>
                                                            </CristalBlueButton>
                                                        </StyledBadge>
                                                    </Box>
                                                ) : (
                                                    <CristalBlueButton onClick={handleProfile} disableRipple>
                                                        <img
                                                            src={require('./../images/icon-settings.svg').default}
                                                            alt="CristalHub"
                                                            className="CristalSettingsButton"/>
                                                    </CristalBlueButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*  Contenu spécifique de chaque composant */}
                    {React.Children.map(children, child =>
                        React.cloneElement(child)
                    )}
                </Box>
            </Container>
        </>
    );
}
export default Layout;
